<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div id="list">
        <div class="title-sort-box">
          <h3>{{ $t('subMenu.notice') }}</h3>
          <div class="sort-side">
            <select class="isf term-sel" v-model="searchCondition">
              <option value="">{{ $t('notice.search-condition1') }}</option>
              <option value="noticeSj">{{
                $t('notice.search-condition2')
              }}</option>
              <option value="noticeCn">{{
                $t('notice.search-condition3')
              }}</option>
              <option value="crtrNm">{{
                $t('notice.search-condition4')
              }}</option>
            </select>
            <span class="search-icon-wrap">
              <input
                type="text"
                class="isf"
                v-model="searchData"
                maxlength="20"
                dataType="LITERAL"
                v-on:keyup.enter="onSearch"
              />
              <button class="ic-search" @click="onSearch">
                {{ $t('common.btn-search') }}
              </button>
            </span>
          </div>
        </div>
        <!--/title-sort-box-->
        <list-form
          :columns="columns"
          :fetchApi="fetchNoticeList"
          :page="page"
          @detail="goNoticeDetail"
          ref="noticeList"
        />
      </div>
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
import { fetchNoticeList } from '@/api/noticeApi.js';
import ListForm from '@/components/common/ListForm';
import CommonMixins from '@/mixins/CommonMixins.js';
export default {
  components: { ListForm },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.about'),
        this.$i18n.t('subMenu.notice'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.about'),
        smallTitle: this.$i18n.t('menu.about-eng'),
      },
      columns: [
        {
          label: this.$i18n.t('notice.table-column1'),
          field: 'noticeSj',
          width: '70%',
        },
        {
          label: this.$i18n.t('notice.table-column2'),
          field: 'rdcnt',
          sortable: false,
          width: '7%',
        },

        {
          label: this.$i18n.t('notice.table-column3'),
          field: 'creatDt',
          formatFn: val => CommonMixins.methods.common_getDateString2(val),
          width: '10%',
        },
        {
          label: this.$i18n.t('notice.table-column4'),
          field: 'fileNm',
          html: true,
          width: '10%',
        },
      ],
      searchCondition: '',
      searchData: '',
      page: this.$route?.params?.page || 1,
    };
  },
  methods: {
    // 공지사항 목록
    fetchNoticeList(params) {
      return fetchNoticeList(params);
    },
    // 검색
    onSearch() {
      let params = {
        page: 1,
        srchCondition: this.searchCondition,
        srchData: this.searchData,
      };
      this.$refs.noticeList.onSearch(params);
    },
    // 공지상세페이지로 이동
    goNoticeDetail({ noticeNo, page }) {
      this.$router.push({ name: 'noticeDetail', params: { noticeNo, page } });
    },
  },
};
</script>
<style lang=""></style>
